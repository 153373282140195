<template>
  <div>
    <section class="section_0" :style="`transform: translate3d(0px, ${top / 3}px, 0px);-ms-transform: translate3d(0px, ${top / 3}px, 0px)`">
      <Swiper @blankHeight="getBlankHeight"></Swiper>

      <!-- <el-carousel
              arrow="always"
              :loop="true"
              :autoplay="false"
              :height="height"
          >
              <el-carousel-item>
                  <img class="swiper-img" src="../assets/home/banner1.jpg" />
              </el-carousel-item>
              <el-carousel-item>
                  <img class="swiper-img" src="../assets/home/banner2.jpg" />
              </el-carousel-item>
              <el-carousel-item>
                  <img class="swiper-img" src="../assets/home/banner3.jpg" />
              </el-carousel-item>
          </el-carousel> -->
    </section>

    <!-- 公司介绍 -->
    <section class="section_6">
      <b-container class="Home" fluid="sm">
        <b-row>
          <b-col class="headline">
            <h1 class="main-title">公司介绍</h1>
            <h5 class="sub-title">Company Profile</h5>
          </b-col>
        </b-row>
        <b-row>
          <b-col class="part part_left explain company" xl="7" order-xl="1" order="20">
            <h4>长春市把手科技有限公司</h4>
            <span class="blue_line"></span>
            <article>
              <!--              长春市把手科技有限公司成立于2015年,是东北早期从事法律大数据研发与应用的科技企业之一,-->
              <!--              公司以“推开法律职业变革之门”为使命，经创业孵化支持，自主研发了“把手案例”(www.lawsdata.com)法律文书和法律法规数据库等核心法律产品。2017年，公司获得科大讯飞战略投资，并与科大讯飞形成全面合作伙伴关系。2018年，公司被评为国家级高新技术企业。-->
              <!--              公司自成立以来，一直与吉林大学法学院保持着长期战略合作伙伴关系，并将吉林大学作为主要人才来源，目前拥有法学、统计学、管理学、计算机等专业的优秀员工80余人。-->
              长春市把手科技有限公司成立于2015年，是东三省最早从事法律大数据开发与应用的科技企业之一。我司于2017年获得科大讯飞技术入股，2018年被评为国家级高新技术企业和吉林省科技型中小企业，2022年“吉企查-企业合规法律风险自检平台”入选2022政法智能化建设智慧创新产品，同年，被中国科学技术协会评为“科创中国”新锐企业，2023年获得全国商业科技进步奖一等奖。
            </article>
            <b-link to="/company" class="bottom-a">
              <span class="button">查看详情</span>
            </b-link>
          </b-col>
          <b-col class="part part_right pic laboratory" xl="5" order-xl="20" order="1">
            <h3>把手科技-法律数据实验室</h3>
            <p>Legal data Lab</p>
            <div class="count_wrap">
              <div class="count_wrap_wall">
                <div class="count_img">
                  <countTo ref="ct1" :startVal="0" :endVal="1.4" :duration="3000" separator="" :decimals="1"></countTo>
                  <b>亿+</b>
                </div>
                <span class="b_span">裁判文书</span>
              </div>

              <div class="count_wrap_wall">
                <div class="count_img">
                  <countTo ref="ct2" :startVal="0" :endVal="600" :duration="3000" separator=""></countTo>
                  <b>万+</b>
                </div>
                <span class="b_span">检察文书</span>
              </div>
              <div class="count_wrap_wall">
                <div class="count_img">
                  <countTo ref="ct3" :startVal="0" :endVal="200" :duration="3000" separator=""></countTo>
                  <b>万+</b>
                </div>
                <span class="b_span">法律法规</span>
              </div>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </section>

    <!-- 我们的产品 -->
    <section class="section_1">
      <b-container class="Home" fluid="sm">
        <b-row>
          <b-col class="headline">
            <h1 class="main-title">我们的产品</h1>
            <h5 class="sub-title">Our Products</h5>
          </b-col>
        </b-row>
        <b-row class="main-line">
          <b-col class="part part_left pic" :class="{ on: sec1 }" xl="6" order-xl="1" order="20">
            <img src="../assets/home/home1.png" />
          </b-col>
          <b-col class="part part_right explain" :class="{ on: sec1 }" xl="6" order-xl="20" order="1">
            <h3>法律数据库</h3>
            <article>
              "把手案例"（www.lawsdata.com）是把手科技有限公司的核心产品，内含全国司法文书数据库和法律法规数据库两大主要组成部分。司法文书数据库收录了全国各级法院、检察院公开裁判文书、检察文书，并向法官、检察官、律师、法律相关专业师生等相关人群提供检索服务。"把手案例"司法文书数据库目前收录的有效文书超过1.4亿篇，其中裁判文书1亿4000余万份，检察文书600余万份，有效文书收录量位于行业前列。
            </article>
            <article>
              法律法规数据库收录了全国人大、国务院、地方人民政府等立法机关发布的相关法律、法规、立法解释、司法解释、部门规章、地方政府规章、规范性文件等，达200余万篇。在保证搜索条件细致全面的同时，把手案例还为用户提供了一目了然的搜索结果可视化服务，实实在在为用户多想一步。
            </article>
            <b-link to="/product" class="bottom-a">
              <span class="button">查看详情</span>
            </b-link>
          </b-col>
        </b-row>
      </b-container>
    </section>

    <!-- 司法辅助工具 -->
    <section class="section_2 bg">
      <b-container class="Home" fluid="sm">
        <b-row>
          <b-col class="part part_left explain" :class="{ on: sec2 }" xl="6" order-xl="20" order="1">
            <h3>司法辅助工具</h3>
            <div class="article">
              <p>刑事精准化量刑辅助系统</p>
              <ul class="ht-list">
                <li>量刑精准计算</li>
                <li>类案智能推送</li>
                <li>多维度数据分析</li>
              </ul>
              <p>把手法律助手</p>
              <ul class="ht-list">
                <li>法规快速检索引用</li>
                <li>繁杂法规一键帮写</li>
                <li>文书样式一键优化</li>
              </ul>
            </div>
            <b-link to="/product" class="bottom-a">
              <span class="button">查看详情</span>
            </b-link>
          </b-col>
          <b-col class="part part_right pic" :class="{ on: sec2 }" xl="6" order-xl="1" order="20">
            <img src="../assets/home/home2.png" />
          </b-col>
        </b-row>
      </b-container>
    </section>

    <!-- 行政辅助应用 -->
    <section class="section_3" v-show="hide">
      <b-container class="Home" fluid="sm">
        <b-row>
          <b-col class="part part_left pic" :class="{ on: sec3 }" xl="6" order-xl="1" order="20">
            <img src="../assets/home/home3.png" />
          </b-col>
          <b-col class="part part_right explain" :class="{ on: sec3 }" xl="6" order-xl="20" order="1">
            <h3>行政辅助应用</h3>
            <div class="article">
              <p>行政监管执法微信小程序</p>
              <div class="article_ul_wrap">
                <ul class="article_ul_1" :class="miniScreen ? '' : 'right-line'">
                  <li>多用户角色设计</li>
                  <span v-if="miniScreen">根据不同角色进行功能区分设计，降低用户使用难度。</span>
                  <li>任务周期全覆盖</li>
                  <span v-if="miniScreen">任务进行全过程均有监管小程序辅助，不怕出错。</span>
                  <li>语音输入提高效率</li>
                  <span v-if="miniScreen">文本支持语音输入，提高输入效率。</span>
                  <li>集成手册随检随查</li>
                  <span v-if="miniScreen">执行手册供执法人员随用随查，便于及时纠错。</span>
                </ul>
                <ul class="article_ul_2" v-if="!miniScreen">
                  <li>根据不同角色进行功能区分设计，降低用户使用难度。</li>
                  <li>任务进行全过程均有监管小程序辅助，不怕出错。</li>
                  <li>文本支持语音输入，提高输入效率。</li>
                  <li>执行手册供执法人员随用随查，便于及时纠错。</li>
                </ul>
              </div>
            </div>
            <b-link to="/product" class="bottom-a">
              <span class="button">查看详情</span>
            </b-link>
          </b-col>
        </b-row>
      </b-container>
    </section>

    <!-- 企业风控系统 -->
    <section class="section_4">
      <b-container class="Home" fluid="sm">
        <b-row>
          <b-col class="part part_left explain" :class="{ on: sec4 }" xl="6" order-xl="20" order="1">
            <h3>企业风控系统</h3>
            <div class="article">
              <p>企业法律风险自检系统</p>
              <ul class="ht-list">
                <li>自助问答</li>
                <li>行业风险自检</li>
                <li>关键信息智能屏蔽</li>
              </ul>
              <p>企业风险指引系统</p>
              <ul class="ht-list">
                <li>企业风险提示</li>
                <li>规避风险指引</li>
                <li>法条精准推荐</li>
              </ul>
            </div>
            <b-link to="/product" class="bottom-a">
              <span class="button">查看详情</span>
            </b-link>
          </b-col>
          <b-col class="part part_right pic" :class="{ on: sec4 }" xl="6" order-xl="1" order="20">
            <img src="../assets/home/home4.png" />
          </b-col>
        </b-row>
      </b-container>
    </section>

    <!-- 公共法律服务 -->
    <section class="section_5 bg">
      <b-container class="Home" fluid="sm">
        <b-row>
          <b-col class="part part_left pic" :class="{ on: sec5 }" xl="6" order-xl="1" order="20">
            <img src="../assets/home/home5.png" />
          </b-col>
          <b-col class="part part_right explain" :class="{ on: sec5 }" xl="6" order-xl="20" order="1">
            <h3>公共法律服务</h3>
            <div class="article">
              <p>常见法律问题智能咨询系统</p>
              <ul class="ht-list">
                <li>引导式自助咨询</li>
                <li>法律意见书自动生成</li>
                <li>类案法规精准推荐</li>
              </ul>
              <p>常见法律文书自动生成系统</p>
              <ul class="ht-list">
                <li>引导式精准问答</li>
                <li>文书类别范围广</li>
                <li>智能解析精准生成</li>
              </ul>
            </div>
            <b-link to="/product" class="bottom-a">
              <span class="button">查看详情</span>
            </b-link>
          </b-col>
        </b-row>
      </b-container>
    </section>

    <!-- 合作伙伴 -->
    <!-- <section class="section_7">
      <b-container class="Home" fluid="sm">
        <b-row>
          <b-col class="headline">
            <h1 class="main-title">合作伙伴</h1>
            <h5 class="sub-title">Cooperative Partner</h5>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <el-carousel arrow="never" :loop="true" :autoplay="true" indicator-position="outside">
              <el-carousel-item v-for="(itm, idx) in Math.ceil(joinHandsList.length / each)" :key="idx + 'w'">
                <div class="partner_wrap">
                  <img
                    v-for="(item, index) in joinHandsList.slice(idx * each, (idx + 1) * each)"
                    :key="index + 'x'"
                    :src="require('../assets/homecopy/hands_' + (idx * each + index + 1) + '.png')"
                    :title="item"
                  />
                </div>
              </el-carousel-item>
            </el-carousel>
          </b-col>
        </b-row>
      </b-container>
    </section> -->
  </div>
</template>

<script>
import { joinHandsList } from "@/baseData/index";
import countTo from "vue-count-to";
import Swiper from "@/components/Swiper.vue";

export default {
  components: { countTo, Swiper },
  data() {
    return {
      joinHandsList,
      hide: false,
      miniScreen: false,
      hList: [], //触发动画高度数组
      sec1: false,
      sec2: false,
      sec3: false,
      sec4: false,
      sec5: false,
      started: false, //count 是否执行完毕
      top: 0,
      each: 12, //底部轮播的单页数量
    };
  },
  mounted() {
    this.miniScreen = document.body.clientWidth < 768; //是否是小屏幕
    if (document.body.clientWidth <= 440) {
      this.each = 4;
    }
    this.setOffsetHeight();
    if (this.IEVersion() > 9) {
      this.toScroll(100000);
    } else {
      window.addEventListener("scroll", this.scrollHandle); //绑定页面滚动事件
    }
    // const that = this
    // window.onresize = function () {
    //   that.$emit('blankHeight', window.innerWidth > 992 ?  412 : 0)
    // };
  },
  methods: {
    getBlankHeight(height) {
      this.$emit("blankHeight", height);
    },
    // 绑定的scroll方法
    scrollHandle(e) {
      let index = 0;
      let top = document.body.scrollTop || document.documentElement.scrollTop;
      // let top = e.srcElement.scrollingElement.scrollTop;    // 获取页面滚动高度
      this.top = top;
      if (document.body.clientWidth <= 1368) return;
      this.toScroll(top);
    },
    // 控制每个section的动画执行的时机
    toScroll(top) {
      for (let i = 0; i < 5; i++) {
        if (top > this.hList[i]) {
          this[`sec${i + 1}`] = true;
          // this[`sec${i + 1}`] = top > this.hList[i];//可收回
        }
      }
    },
    // 获取每个section的高度，已确定动画执行时间
    setOffsetHeight() {
      let prevElem = 0;
      for (let i = 0; i < 6; i++) {
        prevElem += i > 0 ? document.querySelector(".section_" + (i - 1)).offsetHeight : 0;
        const elem = document.querySelector(".section_" + i).offsetHeight;
        this.hList[i] = elem + prevElem;
      }
      this.toScroll(document.documentElement.scrollTop || document.body.scrollTop);
    },
    IEVersion() {
      var userAgent = navigator.userAgent; //取得浏览器的userAgent字符串
      var isIE = userAgent.indexOf("compatible") > -1 && userAgent.indexOf("MSIE") > -1; //判断是否IE<11浏览器
      var isEdge = userAgent.indexOf("Edge") > -1 && !isIE; //判断是否IE的Edge浏览器
      var isIE11 = userAgent.indexOf("Trident") > -1 && userAgent.indexOf("rv:11.0") > -1;
      if (isIE) {
        var reIE = new RegExp("MSIE (\\d+\\.\\d+);");
        reIE.test(userAgent);
        var fIEVersion = parseFloat(RegExp["$1"]);
        if (fIEVersion == 7) {
          return 7;
        } else if (fIEVersion == 8) {
          return 8;
        } else if (fIEVersion == 9) {
          return 9;
        } else if (fIEVersion == 10) {
          return 10;
        } else {
          return 6; //IE版本<=7
        }
      } else if (isEdge) {
        return "edge"; //edge
      } else if (isIE11) {
        return 11; //IE11
      } else {
        return -1; //不是ie浏览器
      }
    },
  },
};
</script>

<style lang="less" scoped>
section {
  position: relative;
  //background-color: #fff;
  background-color: #fafbfc;
  &:not(:first-child) {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }
  &.bg {
    background-color: #f4f7fa;
  }
  .headline {
    text-align: center;
    .main-title {
      color: #222;
      font-size: 4.8rem;
      font-weight: 300;
    }
    .sub-title {
      color: #bababa;
      font-size: 1.6rem;
    }
  }
  .part {
    padding-left: 20px;
    padding-right: 20px;
    > img {
      width: 670px;
      // height: 524px;
      max-width: 100%;
      // margin: 30px auto;
    }
    &.explain {
      padding-left: 3rem;
      padding-right: 3rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    h3 {
      // margin-top: 10rem;
      font-size: 3.6rem;
      font-weight: 400;
      color: #333333;
    }
    article {
      text-indent: 2em;
      text-align: justify; //段落文字实现左右对齐
      text-justify: inter-ideograph; // 段落文字实现左右对齐
      & + article {
        margin-top: 0;
      }
    }
    article,
    .article {
      margin-top: 40px;
      font-size: 1.5rem;
      font-weight: 400;
      color: #555555;
      line-height: 3.6rem;
      p {
        margin-bottom: 0;
        font-size: 1.8rem;
        font-weight: 400;
        color: #333333;
      }
      .ht-list {
        padding: 5px;
        display: flex;
        text-align: left;
        justify-content: flex-start;
        margin-bottom: 2rem;
        li {
          margin-right: 70px;
          // width: 140px;
        }
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
    .button {
      display: inline-block;
      margin-top: 9rem;
      width: 14rem;
      height: 4rem;
      line-height: 4rem;
      background: rgba(22, 120, 255, 0);
      border: 1px solid #015aab;
      border-radius: 20px;
      text-align: center;
      font-size: 1.6rem;
      font-weight: 400;
      color: #015aab;
      cursor: pointer;
      transition: all 0.3s;
      &:hover {
        color: #fff;
        background-color: #015aab;
      }
    }
  }
}
.section_1 {
  padding-top: 80px;
  padding-bottom: 60px;
  .main-line {
    margin-top: 3rem;
  }
}
.section_2,
.section_4 {
  .part .button {
    margin-top: 7.5rem;
  }
}
.section_3,
.section_5 {
  .part .button {
    margin-top: 4rem;
  }
  .article_ul_wrap {
    display: flex;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-align: justify;
    text-justify: inter-ideograph;
    .article_ul_1 {
      width: 30%;
      padding: 0;
      margin: 0;
      text-indent: 20px;
      &.right-line {
        padding-right: 30px;
        border-right: 1px dashed #ddd;
      }
    }
    .article_ul_2 {
      width: 62%;
      margin: 0;
      padding-left: 30px;
      list-style: none;
      li {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
}
.section_6 {
  background: url("../assets/home/home6.jpg") no-repeat center center/cover;
  padding-top: 100px;
  padding-bottom: 100px;
  .headline {
    margin-bottom: 36px;
  }
  .company {
    padding: 6rem !important;
    background: url("../assets/home/company.png") no-repeat center center/cover;
    h4 {
      font-size: 2.4rem;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #333333;
      line-height: 30px;
    }
    .blue_line {
      margin-top: 2rem;
      display: inline-block;
      width: 40px;
      height: 3px;
      background: #015aab;
    }
    .button {
      margin-top: 40px;
    }
  }
  .laboratory {
    padding: 4rem;
    background: url("../assets/home/laboratory.png") no-repeat center center/cover;
    font-size: 30px;
    font-weight: 300;
    color: #fff;
    line-height: 48px;
    h3 {
      color: #fff;
      font-size: 3rem;
      text-align: center;
    }
    > p {
      text-align: center;
      font-size: 1.4rem;
      letter-spacing: 1rem;
    }
    .count_wrap {
      position: relative;
      display: flex;
      justify-content: space-around;
      padding: 4rem 0;
      margin-top: 6rem;
      text-align: center;
      .count_wrap_wall {
        margin: 0 10px;
      }
      .count_img {
        span {
          font-size: 3.8rem;
          font-weight: 400;
        }
        b {
          font-size: 2rem;
          display: inline-block;
        }
      }
      .b_span {
        font-size: 1.6rem;
        margin: 20 auto 0;
        font-weight: 200;
      }
      &::before,
      &::after {
        content: "";
        position: absolute;
        display: inline-block;
        width: 100%;
        height: 1px;
        background: #ffffff;
        opacity: 0.2;
      }
      &::before {
        top: 0;
      }
      &::after {
        bottom: 0;
      }
    }
  }
}
.section_7 {
  padding-top: 100px;
  padding-bottom: 100px;
  background-color: #fafbfc;
  .partner_wrap {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin-top: 40px;

    > img {
      width: 14.6%;
      margin: 1%;
      box-shadow: 0 0 10px #eee;
    }
  }
}

// carousel
/deep/ .el-carousel__item.is-animating {
  transition: transform 0.8s ease-in-out;
}
/deep/ .el-carousel__item img {
  width: 100%;
}
/deep/ .el-carousel__arrow {
  outline: none !important;
  border: 1px solid #fff;
  font-size: 1.6rem;
  width: 3.6rem;
  height: 3.6rem;
}
/deep/ .el-carousel__arrow:hover {
  background-color: #015aab;
  border: 1px solid #015aab;
}
/deep/ .section_0 .el-carousel__indicators--horizontal {
  bottom: 5%;
}
</style>
<style lang="less" scoped>
.overs {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}
@media screen and (min-width: 1368px) {
  .section_1,
  .section_2,
  .section_3,
  .section_4,
  .section_5 {
    .part {
      transition: 0.8s ease;
      &.part_left {
        opacity: 0;
        transform: translateX(-50%);
      }
      &.part_right {
        opacity: 0;
        transform: translateX(50%);
      }
      &.on.part_left {
        opacity: 1;
        transform: translateX(0%);
      }
      &.on.part_right {
        opacity: 1;
        transform: translateX(0%);
      }
    }
  }
  .Home {
    max-width: 1368px !important;
    padding-left: 0;
    padding-right: 0;
    overflow: hidden;
  }
}
@media screen and (max-width: 1368px) {
  section .part {
    .button {
      margin-top: 2rem !important;
    }
    h3 {
      font-size: 3rem;
      margin-top: 2rem;
    }
    .article,
    article {
      margin-top: 2rem;
      & + article {
        margin-top: 0;
      }
    }
    .blue_line {
      margin-top: 2rem;
    }
  }
}
@media screen and (min-width: 1200px) and (max-width: 1367px) {
  section .part {
    &.explain {
      padding: 0;
    }
    h3 {
      margin-top: 0;
    }
  }
}
@media screen and (min-width: 992px) and (max-width: 1200px) {
  /deep/ .el-carousel__container {
    height: 240px;
  }
  section .part.pic {
    text-align: center;
  }
}

@media screen and (max-width: 768px) {
  /deep/ .el-carousel__container {
    height: 350px;
  }
  section {
    .part {
      .article,
      article {
        .ht-list {
          display: inherit;
        }
      }
      &.pic {
        text-align: center;
      }
      img {
        width: 70%;
      }
    }
    .partner_wrap {
      img {
        width: 23% !important;
      }
    }
  }
  .section_6 .company {
    padding: 3rem !important;
  }
}
@media screen and (max-width: 440px) {
  /deep/ .el-carousel__container {
    height: 240px;
  }

  section {
    &.section_1,
    &.section_2,
    &.section_3,
    &.section_4,
    &.section_5,
    &.section_6,
    &.section_7 {
      padding-top: 3rem;
      padding-bottom: 3rem;
      .partner_wrap {
        margin-top: 1rem;
        img {
          width: 48% !important;
          height: calc(48vw / 1.91);
        }
      }
      .main-title {
        font-size: 3rem;
        font-weight: 400;
      }
      .sub-title {
        font-size: 1.4rem;
      }
      .main-line {
        margin-top: 0 !important;
      }
      h3 {
        text-align: center;
        font-size: 2.4rem;
      }
      .part article {
        .overs;
        & + article {
          display: none;
        }
      }
      .part .article {
        text-align: center;
        p {
          display: none;
        }
        p:first-child {
          display: block;
        }
        span {
          display: none;
        }
        .ht-list {
          margin-bottom: 0;
          &:last-child {
            display: none;
          }
          li {
            list-style: none;
            text-indent: 0;
            margin-right: 0;
          }
        }
      }
      .bottom-a {
        text-align: center;
        .button {
          width: 10rem;
          height: 3rem;
          font-size: 1.4rem;
          line-height: 3rem;
        }
      }

      .article_ul_wrap {
        .overs;
        text-align: center;
        .article_ul_1 {
          text-indent: 0;
          width: 100%;
        }
      }
    }
  }
  .section_6 {
    padding-bottom: 0 !important;
    .company {
      h4 {
        text-align: center;
        font-size: 2rem;
      }
      .blue_line {
        display: none;
      }
    }
    .laboratory {
      padding: 3rem;
      h3 {
        word-break: keep-all;
        font-size: 2.4rem;
      }

      .count_wrap {
        margin-top: 1rem;
        padding: 1rem 0;
        font-size: 2.2rem;
        .count_img {
          margin: 0 20px;
          span {
            font-size: 2.2rem;
          }
          b {
            font-size: 1.4rem;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 375px) {
  /deep/ .el-carousel__container {
    height: 300px;
  }
}
</style>
